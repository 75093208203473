import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAYED_DATE_FORMAT = 'DD-MMM-YYYY';

export const addDaysToDate = (date, days) =>
  moment(date).add(days, 'days').toDate();

export const subtractDaysToDate = (date, days) =>
  moment(date).subtract(days, 'days').toDate();

export const formatDate = newDate => moment(newDate).format(DATE_FORMAT);

/**
 * A momentjs format wrapper
 * if we ever need to migrate off from momentjs, we can reuse this
 * @param {Object} date a string, Date or moment date
 * @param {String} format
 * @param {String} locale
 * @return {String} date formatted as string
 */
export const formatDateWithFormat = (date, format, locale = moment.locale()) =>
  moment(date).locale(locale).format(format);

export const formatDisplayDate = (
  displayDate,
  format = DISPLAYED_DATE_FORMAT
) => {
  const date = moment(displayDate);
  if (!date.isValid()) {
    return '';
  }
  return date.format(format);
};

export const isDateValid = date => moment(date).isValid();
