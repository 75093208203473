import { Fragment } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import CloseButton from '../CloseButton';
import BackButton from '../BackButton';

import styles from './ModalHeader.module.scss';

const cx = classNames.bind(styles);

const ModalHeader = ({
  className = '',
  titleClassName = '',
  label = '',
  onRequestClose = () => {},
  dataCy = '',
  dataTest = '',
  showBack = false,
  showClose = true
}) => {
  const modalHeaderClassName = cx(className, 'modal-header', {
    'modal-header--show-back': showBack
  });
  const titleClassNames = cx('modal-header__title', titleClassName);
  const backButtonClassName = cx('modal-header__back-button');
  const closeButtonClassName = cx('modal-header__close-button');

  return (
    <Fragment>
      <div className={modalHeaderClassName}>
        {showBack && (
          <BackButton
            onClick={onRequestClose}
            dataCy={dataCy}
            dataTest={dataTest}
            className={backButtonClassName}
          />
        )}
        <h4 className={titleClassNames}>{label}</h4>
        {showClose && (
          <CloseButton
            onClick={onRequestClose}
            dataCy={dataCy}
            dataTest={dataTest}
            className={closeButtonClassName}
          />
        )}
      </div>
    </Fragment>
  );
};

ModalHeader.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onRequestClose: PropTypes.func,
  dataCy: PropTypes.string,
  dataTest: PropTypes.string,
  showBack: PropTypes.bool,
  showClose: PropTypes.bool
};

export default ModalHeader;
